define('@fortawesome/pro-regular-svg-icons', ['exports'], function (exports) { 'use strict';

  var faEllipsisVertical = {
    prefix: 'far',
    iconName: 'ellipsis-vertical',
    icon: [128, 512, ["ellipsis-v"], "f142", "M64 368a48 48 0 1 0 0 96 48 48 0 1 0 0-96zm0-160a48 48 0 1 0 0 96 48 48 0 1 0 0-96zM112 96A48 48 0 1 0 16 96a48 48 0 1 0 96 0z"]
  };

  exports.faEllipsisVertical = faEllipsisVertical;

  Object.defineProperty(exports, '__esModule', { value: true });

});
